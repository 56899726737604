<template>
  <div class="charging-page">
    <div class="charging-header">
      <div class="header-tab">
        <van-tabs v-model="type" @click="changeType">
          <van-tab title="服务收费"></van-tab>
          <van-tab title="增值配件"></van-tab>
        </van-tabs>
      </div>
      <div class="search-container">
        <div class="search-box">
          <div class="search-icon">
            <van-icon name="search" size="19" color="#9C9DAA"></van-icon>
          </div>
          <input type="search" @keypress="searchGoods" placeholder="输入关键词直接搜索收费项" v-model="keywords" >
        </div>
      </div>
      <div class="tabs-container">
        <div class="tabs-box">
          <van-tabs v-model="active" sticky scrollspy @change="changeTabs">
            <van-tab :title="item.title" v-for="(item, index) in cateList" :key="index">
            </van-tab>
          </van-tabs>
        </div>
        <div class="filter-btn-box">
          <div class="filter-btn" @click="visible = !visible">
            <img src="~@/assets/images/icon/icon_filter.svg" class="icon-filter" alt="">
          </div>
          <img src="~@/assets/images/icon/filter_shadow.png" class="shadow" alt="">
        </div>
      </div>
    </div>
    <div class="charging-body">
      <ServiceCard v-show="type == 0" v-if="checkId != -1" ref="serv" :keyword="keywords" :cateid="checkId"/>
      <PartsCard v-show="type == 1" v-if="checkId != -1" ref="parts" :keyword="keywords" :cateid="checkId"/>
    </div>
    <transition name="van-fade">
      <div class="charging-mask" v-if="visible" @click="visible=false"></div>
    </transition>
    <transition name="show">
      <div class="filter-container" v-if="visible">
        <div class="filter-content">
          <div class="filter-item flex-center"
            :class="{ 'active' : active === index }"
            v-for="(item, index) in cateList" :key="index"
            @click="changeTabs(index)">
            {{ item.title }}
          </div>
        </div>
      </div>
    </transition>
    
  </div>
</template>

<script>
  import { Tab, Tabs, Icon, Toast } from 'vant';
  import ServiceCard from './components/Service'
  import PartsCard from './components/Parts'
  import { getCategoryList } from '@/api/common'

  export default {
    components: {
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Icon.name]: Icon,
      ServiceCard,
      PartsCard
    },
    data() {
      return {
        type: 0,
        active: 0,
        keywords: '',
        visible: false,
        cateList: [],
        checkId: -1,
        cateList1: [],
        cateList2: [],
      }
    },
    created() {
      this.getCateList()
    },
    methods: {
      
      getCateList() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getCategoryList({is_sf: 1}).then(res => {
          this.cateList = res.data.list
          this.cateList1 = res.data.list
          toast.clear()
          if (this.$route.query.id) {
            const _id = this.$route.query.id
            this.checkId = _id
            this.active = this.cateList.findIndex(x => x.id == _id)
          } else {
            this.checkId = this.cateList[0].id
          }
          this.$nextTick(() => {
            this.$refs.serv.loadData()
          })
        })
        getCategoryList({is_zz: 1}).then(res => {
          this.cateList2 = res.data.list
        })
      },
      changeType(e) {
        this.type = e
        if (e == 0) {
          this.active = 0
          this.cateList = this.cateList1
          this.checkId = this.cateList[0].id
          this.$nextTick(() => {
            this.$refs.serv.loadData()
          })
        } else {
          this.active = 0
          this.cateList = this.cateList2
          this.checkId = this.cateList[0].id
          this.$nextTick(() => {
            this.$refs.parts.loadData()
          })
        }
      },
      changeTabs(e) {
        this.active = e
        this.checkId = this.cateList[e].id
        this.$nextTick(() => {
          this.visible = false
          if (this.type == 0) {
            this.$refs.serv.loadData()
          } else {
            this.$refs.parts.loadData()
          }
        })
      },
      searchGoods(e) {
        if (e.keyCode == 13) { //如果按的是enter键 13是enter 
          e.preventDefault(); //禁止默认事件（默认是换行） 
          if (this.type == 0) {
            this.$refs.serv.loadData()
          } else {
            this.$refs.parts.loadData()
          }
        } 
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>
<style lang="less">
  .charging-page {
    .van-tabs__line {
      background-color: #186AFF;
      box-shadow: 0 0.2667vw 0.2667vw rgba(24,106,255,.1) 
    }
  }
  .header-tab {
    .van-tabs__line {
      width: 8vw;
    }
    .van-tab {
      font-size: 4vw; 
      font-weight: bold;
      color: #9C9DAA;
    }
    .van-tab--active {
      color: #1C1F20
    }
  }
  .tabs-box {
    .van-tabs__line {
      width: 5.8667vw;
    }
    .van-tab {
      font-size: 3.7333vw;
      font-weight: 500;
      color: #9C9DAA;
      padding: 0 4.6667vw !important;
    }
    .van-tab--active {
      color: #1C1F20;
      font-size: 4.2667vw;
    }
  }
</style>