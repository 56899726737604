<template>
  <div class="parts-box">
    <Empty photo="2" label="暂无内容信息" v-if="list.length == 0"/>
    <div class="parts-list" v-else>
      <a :href="item.url || 'https://work.weixin.qq.com/kfid/kfc13b6b5011a756105?enc_scene=ENCX2ZP57viwXNSm8jYnnVPZC'" v-for="(item, index) in list" :key="index" class="part-card">
        <div class="photo">
          <img :src="item.photo" class="contain-img" alt="">
        </div>
        <div class="info">
          <div class="title">{{ item.title }}</div>
          <div class="desc">配件价格：{{ item.price }}元</div>
        </div>
        <div class="more" v-if="item.url">
          <span>咨询购买</span>
          <van-icon name="arrow" size="2.6667vw" color="#999999"></van-icon>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import { getPartsList } from '@/api/common'
  import { Toast, Icon } from 'vant'
  import Empty from '@/components/empty';

  export default {
    components: {
      Empty,
      [Icon.name]: Icon
    },
    props: {
      keyword: {
        type: String,
        default: ''
      },
      cateid: {
        
      }
    },
    data() {
      return {
        list: []
      }
    },
    methods: {
      loadData() {
        const params = {
          keyword: this.keyword,
          id: this.cateid
        }
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getPartsList(params).then(res => {
          this.list = res.data.list
          this.cur = 0
          toast.clear()
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>