<template>
  <div class="charging-service">
    <div class="sidebar-box" v-if="list.length > 0">
      <div 
        class="side-item"
        :class="{ 'active': cur==index, 'last': cur==index+1, 'next': cur==index-1 }" 
        v-for="(item,index) in list" :key="index"
        @click="cur=index">
        <div class="item-main">
          <span>
            {{ item.title }}
            <img src="~@/assets/images/icon/icon_tab_cur.svg" v-if="cur == index" alt="">
          </span>
        </div>
        
      </div>
    </div>
    <div class="sidebar-main" v-if="list.length > 0">
      <div class="charg-item van-hairline--bottom" :class="{ 'hasd': item.detail }" v-for="(item, index) in list[cur].infos" :key="index">
        <div class="charg-item-label" @click="item.visible = !item.visible">
          <div class="key">{{ item.title }}</div>
          <div class="val">{{ item.fee }}</div>
          <div class="arr-down" :class="{ 'active': item.visible }" v-if="item.tips">
            <img src="~@/assets/images/icon/icon_arr_down.svg" width="100%" alt="">
          </div>
        </div>
        <transition name="slidedown" v-if="item.tips">
          <div class="charg-item-content" v-if="item.visible">
            <div class="content">
              {{ item.tips }}
            </div>
          </div>
        </transition>
      </div>
    </div>
    <Empty photo="2" label="暂无内容信息" v-if="list.length == 0"/>
  </div>
</template>

<script>
  import { getFeeInfo } from '@/api/common'
  import { Toast } from 'vant'
  import Empty from '@/components/empty'

  export default {
    components: {
      Empty
    },
    props: {
      keyword: {
        type: String,
        default: ''
      },
      cateid: {
        
      }
    },
    data() {
      return {
        cur: 0,
        list: []
      }
    },
    methods: {
      // 获取列表
      loadData() {
        const params = {
          keyword: this.keyword,
          id: this.cateid
        }
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getFeeInfo(params).then(res => {
          this.list = res.data.list.map(el => {
            if(el.infos) {
              el.infos.forEach(item => {
                item.visible = false
              })
            }
            return el
          })
          this.cur = 0
          toast.clear()
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>