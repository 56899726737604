<template>
  <div class="empty-box">
    <div class="empty-img">
      <img src="~@/assets/images/empty.jpg" v-if="photo == '1'" width="100%" alt="">
      <img src="~@/assets/images/empty_2.jpg" v-if="photo == '2'" width="100%" alt="">
      <img src="~@/assets/images/empty_3.jpg" v-if="photo == '3'" width="100%" alt="">
      <img src="~@/assets/images/empty_4.jpg" v-if="photo == '4'" width="100%" alt="">
    </div>
    <span>{{ label }}</span>
  </div>
</template>

<script>
  export default {
    name: 'empty',
    props: {
      label: {
        type: String,
        value: ''
      },
      photo: {
        type: String,
        value: '1'
      }
    },
    data() {
      return {
      }
    }
  }
</script>

<style lang="less" scoped>
  .empty-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 32vw;
    width: 100%;
    img {
      width: 64.1333vw;
    }
    span {
      font-size: 3.7333vw;
      color: #9C9DAA;
      line-height: 1.1;
      margin-top: 3.2vw;
    }
  }
</style>